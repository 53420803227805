import { Component } from '@angular/core';
import { CtControlConfiguration } from "@ctsolution/ct-framework";
import { Currency } from "../../../../../core/enum/currency";
import { CURRENCY_CONTROL } from "./currency.control";
import { CONTRACT_CURRENCY } from "./currency.control-options";

@Component({
    selector: 'app-currency-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class CurrencyControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(CURRENCY_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        this.control
            .setValueOptions(CONTRACT_CURRENCY)
            .setValue(Currency.EUR)
            .setDisabled(true);

    }

}
