<div class="dynamic-fields-cntr"
     [hidden]="!specificContractConfiguration?.contractType">

    <mat-card>

        <mat-card-content>

            <mat-card-title>

                {{ specificContractConfiguration?.contractType?.Description ?? 'Campi aggiuntivi' }}

            </mat-card-title>

            <ct-form-container
                #FormContainer
                *ngIf="modelConfiguration"
                [configuration]="modelConfiguration"></ct-form-container>

        </mat-card-content>

        <mat-card-actions *ngIf="specificContractConfiguration?.actionsEnabled">

            <ct-button [configuration]="cancelButton"></ct-button>

        </mat-card-actions>

    </mat-card>

</div>
