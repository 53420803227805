import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
    CtBinaryOperator, CtModelConfiguration,
    CTModelDatatableFilter,
    CtModelDatatableOperators,
    CtRangeControlOptions,
    CtSelectControlOptions, CtSelectControlValue
} from "@ctsolution/ct-framework";

import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi";
import { pairwise, startWith } from "rxjs";
import { GenericElement } from "../../../../core/class/generic-element";
import { ContractTypeListElementDTO } from "../../../../core/controller/contract-types.controller";
import { AutomaticRenewControlComponent } from "./automatic-renew-control/automatic-renew-control.component";
import { CommercialControlComponent } from "./commercial-control/commercial-control.component";
import { ContractNumberControlComponent } from "./contract-number-control/contract-number-control.component";
import { ContractStateControlComponent } from "./contract-state-control/contract-state-control.component";
import { ContractYearControlComponent } from "./contract-year-control/contract-year-control.component";
import { CurrencyControlComponent } from "./currency-control/currency-control.component";
import { CustomerControlComponent } from "./customer-control/customer-control.component";
import { CustomerLocationControlComponent } from "./customer-location-control/customer-location-control.component";
import { InvoiceTypeControlComponent } from "./invoice-type-control/invoice-type-control.component";
import { MonthDurationControlComponent } from "./month-duration-control/month-duration-control.component";
import { OrderDateControlComponent } from "./order-date-control/order-date-control.component";
import { OrderNumberControlComponent } from "./order-number-control/order-number-control.component";
import { SupplierControlComponent } from "./supplier-control/supplier-control.component";
import { TotalControlComponent } from "./total-control/total-control.component";
import { ValidityEndControlComponent } from "./validity-end-control/validity-end-control.component";
import { ValidityStartControlComponent } from "./validity-start-control/validity-start-control.component";

@Component({
    selector: 'app-contract-detail-field',
    templateUrl: './contract-detail-field.component.html',
    styleUrls: ['./contract-detail-field.component.scss']
})
export class ContractDetailFieldComponent {

    form: FormGroup;

    @ViewChild(ValidityStartControlComponent) validityStartControl: ValidityStartControlComponent | null = null;
    @ViewChild(ValidityEndControlComponent) validityEndControl: ValidityEndControlComponent | null = null;
    @ViewChild(OrderDateControlComponent) orderDateControlComponent: OrderDateControlComponent | null = null;
    @ViewChild(OrderNumberControlComponent) orderNumberControlComponent: OrderNumberControlComponent | null = null;
    @ViewChild(ContractNumberControlComponent) contractNumberControlComponent: ContractNumberControlComponent | null = null;
    @ViewChild(ContractYearControlComponent) contractYearControlComponent: ContractYearControlComponent | null = null;
    @ViewChild(AutomaticRenewControlComponent) automaticRenewControlComponent: AutomaticRenewControlComponent | null = null;
    @ViewChild(ContractStateControlComponent) contractStateControlComponent: ContractStateControlComponent | null = null;
    @ViewChild(MonthDurationControlComponent) monthDurationControlComponent: MonthDurationControlComponent | null = null;
    @ViewChild(TotalControlComponent) totalControlComponent: TotalControlComponent | null = null;
    @ViewChild(CurrencyControlComponent) currencyControlComponent: CurrencyControlComponent | null = null;
    @ViewChild(InvoiceTypeControlComponent) invoiceTypeControlComponent: InvoiceTypeControlComponent | null = null;
    @ViewChild(CustomerControlComponent) customerControlComponent: CustomerControlComponent | null = null;
    @ViewChild(SupplierControlComponent) supplierControlComponent: SupplierControlComponent | null = null;
    @ViewChild(CommercialControlComponent) commercialControlComponent: CommercialControlComponent | null = null;
    @ViewChild(CustomerLocationControlComponent) customerLocationControlComponent: CustomerLocationControlComponent | null = null;

    constructor(
        private formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef) {

        this.form = this.formBuilder.group({
            Oid: new FormControl(null)
        });

    }

    ngAfterViewInit() {

        this.setup();

        this.cdr.detectChanges();

    }

    setup() {
        this.validityStartControl
            ?.control
            .setFormParent(this.form)

        this.validityEndControl
            ?.control
            .setFormParent(this.form)

        this.setupStartValidityChangeSubscription();

        this.orderDateControlComponent
            ?.control
            .setFormParent(this.form);

        this.orderNumberControlComponent
            ?.control
            .setFormParent(this.form);

        this.contractNumberControlComponent
            ?.control
            .setFormParent(this.form);

        this.contractYearControlComponent
            ?.control
            .setFormParent(this.form);

        this.automaticRenewControlComponent
            ?.control
            .setFormParent(this.form);

        this.contractStateControlComponent
            ?.control
            .setFormParent(this.form);

        this.monthDurationControlComponent
            ?.control
            .setFormParent(this.form);

        this.setupMonthDurationChangeSubscription();

        this.totalControlComponent
            ?.control
            .setFormParent(this.form);

        this.setupCurrencyChangeSubscription();

        this.currencyControlComponent
            ?.control
            .setFormParent(this.form);


        this.invoiceTypeControlComponent
            ?.control
            .setFormParent(this.form);

        this.setupInvoiceTypeChangeSubscription();

        this.customerControlComponent
            ?.control
            .setFormParent(this.form);

        this.setupCustomerChangeSubscription();

        this.supplierControlComponent
            ?.control
            .setFormParent(this.form);

        this.commercialControlComponent
            ?.control
            .setFormParent(this.form);

        this.customerLocationControlComponent
            ?.control
            ?.setFormParent(this.form);

        this.setupOidChangeSubscription();

    }

    private setupOidChangeSubscription() {

        this.form
            .get('Oid')
            ?.valueChanges
            .subscribe(() => this.contractStateControlComponent?.enableAdditionalStates());

    }

    private setupStartValidityChangeSubscription() {

        this.validityStartControl
            ?.control
            .control
            ?.valueChanges
            .subscribe(() => this.validityEndControl?.updateEndDateControl(this.form.value));

    }

    private setupCurrencyChangeSubscription() {

        this.totalControlComponent?.setTotalSuffixIcon(this.currencyControlComponent?.control.value)

        this.currencyControlComponent
            ?.control
            .control
            ?.valueChanges
            .subscribe(value => this.totalControlComponent?.setTotalSuffixIcon(value))

    }

    private setupInvoiceTypeChangeSubscription() {

        this.invoiceTypeControlComponent
            ?.control
            .control
            ?.valueChanges
            .subscribe(value => {

                const disableDuration: boolean = !value || (this.monthDurationControlComponent?.control.formParent?.disabled ?? false);

                if (disableDuration) {

                    this.monthDurationControlComponent
                        ?.control
                        .setDisabled(true);

                    if (!value) this.monthDurationControlComponent?.control.setValue(null)

                    return;

                }

                let rangeOptions = this.monthDurationControlComponent
                    ?.control
                    .options as CtRangeControlOptions;

                if (!rangeOptions) rangeOptions = CtRangeControlOptions.create();

                rangeOptions
                    .setStep(value === 1000 ? 1 : value);

                this.monthDurationControlComponent
                    ?.control
                    .setDisabled(this.form.disabled)
                    .setValue(rangeOptions.step)
                    .setOptions(rangeOptions);

            });

    }

    private setupMonthDurationChangeSubscription() {

        this.monthDurationControlComponent
            ?.control
            .control
            ?.valueChanges
            .pipe(startWith(null), pairwise())
            .subscribe(([prev, next]: [any, any]) => {

                this.validityEndControl?.updateEndDateControl(this.form.value);

                if (this.totalControlComponent?.control.disabled) { // se disabilitato vuol dire che è abilitato l'autoincrementale

                    // divido per il valore precedente di mesi

                    let currentAmountValue = this.totalControlComponent?.control?.value ?? 0;

                    // console.log(`Prima di iniziare valgo ${currentAmountValue}€`);

                    currentAmountValue = currentAmountValue / (prev ?? 1);

                    // console.log(`Ora valgo ${currentAmountValue}€, perchè il mio valore iniziale è stato diviso per il precedente numero di mesi ${prev}`);

                    this.setTotalValue(currentAmountValue);

                    // this.setTotalValue((this.totalControlComponent?.control?.value ?? 0) / (prev ?? 1));
                    // this.setTotalValue(this.totalControlComponent?.control?.value ?? 0); // effettuo nuovamente il calcolo

                }

            });

    }

    public setupCustomerChangeSubscription() {

        const value = this.customerControlComponent?.control.control?.value;
        const options: CtSelectControlOptions | null = this.customerLocationControlComponent?.control?.options as CtSelectControlOptions;
        if (!options) this.customerLocationControlComponent?.control?.setOptions(CtSelectControlOptions.create());

        options.setLookupFilter(!value ? null : (() => CtModelDatatableOperators
        .create()
        .setFilters([
            CTModelDatatableFilter
                .create()
                .setField('Customer.Oid')
                .setValue(value)
                .setOperatorType(CtBinaryOperator.Equal)
        ])));

        options
        .notifyValuesUpdated()

        this.customerControlComponent
            ?.control
            .control
            ?.valueChanges
            .subscribe(value => {

                this.customerLocationControlComponent
                    ?.control
                    ?.setValue(null)
                    ?.setDisabled(this.form.disabled ?? false);

                const options: CtSelectControlOptions | null = this.customerLocationControlComponent?.control?.options as CtSelectControlOptions;

                if (!options) this.customerLocationControlComponent?.control?.setOptions(CtSelectControlOptions.create());

                options
                    .setLookupFilter(!value ? null : (() => CtModelDatatableOperators
                        .create()
                        .setFilters([
                            CTModelDatatableFilter
                                .create()
                                .setField('Customer.Oid')
                                .setValue(value)
                                .setOperatorType(CtBinaryOperator.Equal)
                        ])))
                    .setLookupResponseMapper((response: CtWebapiGenericResponse<CtModelConfiguration<GenericElement>>) => {

                        if (!this.customerLocationControlComponent?.control.value) {

                            const dataSource = (<Array<GenericElement>>response.Result.DataSource);
                            const selectedValue: GenericElement | undefined = dataSource.find(elm => (<any>elm).IsDefault) ?? dataSource[0];

                            this.customerLocationControlComponent
                                ?.control
                                ?.setValue(selectedValue?.Code);

                        }

                        return (<Array<GenericElement>>response.Result.DataSource ?? [])
                            .map((elm: GenericElement) => {

                                return CtSelectControlValue.create()
                                    .setLabel(elm.Description)
                                    .setValue(elm.Code);

                            });

                    })

                options
                    .notifyValuesUpdated()

            })

    }

    //#endregion

    toggleTotalAmountValidation(contractType: ContractTypeListElementDTO | null) {

        this.totalControlComponent?.control.setDisabled(contractType?.AutoIncrementalAmount ? true : this.form.disabled);

    }

    setTotalValue(value: number | null = null, monthCount: boolean = true) {

        this.totalControlComponent?.control.setValue((value ?? 0) * (monthCount ? (this.monthDurationControlComponent?.control.value ?? 1) : 1));

    }

    //#endregion
}
