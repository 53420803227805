import {Component} from '@angular/core';
import {CtControlConfiguration, CtThemeTypes} from "@ctsolution/ct-framework";
import {CONTRACT_STATE_CONTROL} from "./contract-state.control";
import {ContractState} from "../../../../../core/enum/contract-state";
import {ADDITIONAL_STATES, CREATE_STATES} from "./contract-state.control-options";

@Component({
    selector: 'app-contract-state-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ContractStateControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(CONTRACT_STATE_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        this.control
            .setTheme(CtThemeTypes.MATERIAL)
            .setValueOptions(CREATE_STATES)
            .setValue(ContractState.Active);

    }

    enableAdditionalStates() {

        this.control
            .setValueOptions([...(this.control.valueOptions ?? []), ...ADDITIONAL_STATES]); // in modifica posso avere gli stati scaduto e rinnovato (anche se non è un campo modificabile)

    }

}
