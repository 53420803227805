import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {CONTRACT_CODE_CONTROL} from "./contract-code.control";

@Component({
    selector: 'app-contract-code-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ContractCodeControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(CONTRACT_CODE_CONTROL);

    setupContractCodeByFormValue(value: any) {

        this.control
            .setValue(this.getContractCode(value));

    }

    private getContractCode(value: any): string {

        function pad(value: number | string | null, size: number, replaceCharacter: string = '0'): string {

            let s: string = '';

            if (value) {

                if (!isNaN(+value)) value = value.toString();

                s = value as string;

            }

            while (s.length < size) s = replaceCharacter + s;
            return s;

        }

        const COMMERCIAL_CODE: string = pad(value.Detail.User, 3, 'A');
        const CONTRACT_NUMBER: string = pad(value.Detail.ContractNumber, 3, '0');
        const CONTRACT_YEAR: string = pad(value.Detail.ContractYear, 4, 'Y');
        const CONTRACT_TYPE: string = pad((value?.Info?.ContractType?.Description ?? '').substring(0, 3), 3, 'X');
        return `${COMMERCIAL_CODE}${CONTRACT_NUMBER}/${CONTRACT_YEAR}/${CONTRACT_TYPE}`;

    }

}
