<ct-model
        *ngIf="configuration"
        [configuration]="configuration"></ct-model>

<ng-template #StateCellTemplate let-row="row">

    <app-state-bullet
            [configuration]="stateBulletConfiguration(row.State)"></app-state-bullet>

</ng-template>

<ng-template #legendTemplate>

    <app-state-legend [configuration]="stateLegendConfiguration()"></app-state-legend>

</ng-template>

<ng-template #AmountCellTemplate let-value="value" let-row="row">

    {{ value | currency:getRowCurrencyValue(row) }}

</ng-template>

<ng-template #AmountPerYearCellTemplate let-value="value" let-row="row">

    {{ value | currency:getRowCurrencyValue(row) }}

</ng-template>
