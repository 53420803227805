import {ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {
    CtButtonConfiguration,
    CtFormContainerComponent,
    CtModelConfiguration,
    CtModelRouteData, CtModelService,
    CtModelType, MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {ContractExtraFieldConfiguration} from "./contract-extra-field.configuration";
import {RoleService} from "../../../../core/lib/role.service";
import {ContractTypeListElementDTO} from "../../../../core/controller/contract-types.controller";
import {Subscription} from "rxjs";

const SPECIFICCONTRACT_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("SpecificContract");

@Component({
    selector: 'app-contract-extra-field',
    templateUrl: './contract-extra-field.component.html',
    styleUrls: ['./contract-extra-field.component.scss']
})
export class ContractExtraFieldComponent {

    @ViewChild('FormContainer') formContainer: CtFormContainerComponent<any> | null = null;

    @Output() close: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateAmount: EventEmitter<number | null> = new EventEmitter<number | null>();

    specificContractConfiguration: ContractExtraFieldConfiguration | null = null;
    modelConfiguration: CtModelConfiguration<ContractExtraFieldComponent> | null = null;
    currentValue: any | null = null;
    cancelButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setClass('m-r-10')
        .setLabel("Chiudi")
        .setAction(() => this.close.emit())
        .setMatherialOptions(MAT_RAISED_WARN);

    subscription: Subscription | null = null;

    viewModel = {
        isTech: false
    }

    constructor(
        private formBuilder: FormBuilder,
        private _webapi: CtWebapiService,
        private modelService: CtModelService<any>,
        private roleService: RoleService,
        private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {

        this.setupPermissions()
            .then(() => this.cdr.detectChanges())

    }

    private async setupPermissions() {

        this.viewModel.isTech = await this.roleService.is.tech();

    }

    setup(configuration: ContractExtraFieldConfiguration | null) {

        this.modelConfiguration = null;

        this.specificContractConfiguration = configuration;

        setTimeout(() => {

            if (this.specificContractConfiguration) {

                const routeData: CtModelRouteData = SPECIFICCONTRACT_ROUTEDATA()
                    .setModelType(CtModelType.FORM);

                const request: DataRequest = DataRequest.create()
                    .setController([routeData.controller!])
                    .setAction('FieldsByContractType')
                    .setQueryParams({
                        hideSpinner: true
                    })
                    .setMethod(MethodEnum.POST)
                    .setContentType('application/json-patch+json')
                    .setBody(this.specificContractConfiguration.contractType?.Code);

                const modelConfiguration: CtModelConfiguration<any> = CtModelConfiguration.create<ContractExtraFieldComponent>()
                    .setRouteData(routeData)
                    .setGetFieldsDataRequest(request)
                    .setDefaultActionsEnabled(false)

                this.modelService
                    .getFields(modelConfiguration)
                    .subscribe({
                        next: (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

                            modelConfiguration
                                ?.setupCTModelConfig(response.Result)

                            this.modelConfiguration = modelConfiguration;

                            setTimeout(() => {

                                if (this.currentValue) this.formContainer?.form?.patchValue(this.currentValue);

                                if (this.specificContractConfiguration?.disabled || this.viewModel.isTech) { // in preview deve essere disabilitato il form

                                    this.formContainer?.form?.disable();

                                }

                                this.subscribeToAutoIncrementalAmount(configuration?.contractType ?? null);

                            })

                        },
                        error: () => this.modelService.onError()
                    })

            }

            this.cdr.detectChanges();

        })

    }

    setFormValue(value: any) {

        this.currentValue = value;

    }

    subscribeToAutoIncrementalAmount(contractType: ContractTypeListElementDTO | null) {

        this.resetAutoIncrementalAmountValueChangeSubscription();

        if (contractType?.AutoIncrementalAmount) {

            const incrementalField = 'FinalPrice';
            const objectKey = 'lstSoftwareSubscriptionRowDTO';

            this.subscription = this.formContainer
                ?.form
                ?.valueChanges
                ?.subscribe((value) => {

                    const list = value[objectKey];
                    let amount = 0;

                    if (Array.isArray(list)) {

                        amount = list
                            .reduce((acc: number, oggetto) => {

                                oggetto[incrementalField] = Number(oggetto.InstanceNumber ?? 0) * Number(oggetto.InstancePrice ?? 0);

                                const increment = oggetto[incrementalField];

                                if (increment !== undefined) {

                                    const valueAsNumber = parseInt(increment, 10);

                                    if (!isNaN(valueAsNumber)) return acc + valueAsNumber;

                                }

                                return acc;

                            }, 0);

                    }

                    this.updateAmount.emit(amount);

                }) ?? null;

        }

    }

    ngOnDestroy() {

        this.resetAutoIncrementalAmountValueChangeSubscription();

    }

    private resetAutoIncrementalAmountValueChangeSubscription() {

        this.subscription?.unsubscribe();
        this.subscription = null;

    }

}
