import {ChangeDetectorRef, Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ALERT_EMAIL_CONTROL, ALERT_MANAGEMENT_CONTROL, MONITORING_CONTROL} from "./contract-alert.controls";

@Component({
    selector: 'app-contract-alert-field',
    templateUrl: './contract-alert-field.component.html'
})
export class ContractAlertFieldComponent {

    form: FormGroup;

    monitoringCntrl: CtControlConfiguration = CtControlConfiguration.create(MONITORING_CONTROL);
    alertManagementCntrl: CtControlConfiguration = CtControlConfiguration.create(ALERT_MANAGEMENT_CONTROL);
    alertEmailCntrl: CtControlConfiguration = CtControlConfiguration.create(ALERT_EMAIL_CONTROL);

    constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {

        this.form = this.formBuilder.group({});

        this.monitoringCntrl
            .setFormParent(this.form)
            .setValue(true);

        this.alertManagementCntrl
            .setFormParent(this.form)
            .setValue(true);

        this.alertEmailCntrl
            .setFormParent(this.form);

    }

    ngAfterViewInit() {

        this.cdr.detectChanges();

    }

}
