import {CTFieldInfos} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";


export const SUPPLIER_CONTROL: CTFieldInfos = CTFieldInfos.create({
    Field: "Supplier",
    ControlType: "Lookup",
    LookupInfos: {
        RemoteEndpoint: `${environment.dev.REST_API_SERVER}/Customer/SupplierLookup`,
    },
    FormInfos: {
        DefaultValue: environment.variables.defaults.supplier
    }
} as CTFieldInfos)
