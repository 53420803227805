import {Component, OnInit} from "@angular/core";
import {
    CtBinaryOperator,
    CtCardConfiguration,
    CTGeneralService,
    CtModelConfiguration,
    CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import {
    ACTIVE_CONTRACTS_CARD,
    BILLING_NOTIFICATION_TRIGGER,
    CONTRACT_LIST_CARD,
    CONTRACT_OPTION_LIST,
    CONTRACT_TYPE_LIST,
    EXPIRED_CONTRACTS_CARD,
    EXPIRING_CONTRACTS_CARD,
    GUARANTEE_NOTIFICATION_TRIGGER,
    NEW_CONTRACT_CARD,
    NEW_CUSTOMER_CARD
} from "./dashboard.widget";
import {ContractState} from "../../core/enum/contract-state";
import {ContractController} from "../../core/controller/contract.controller";
import {RoleService} from "../../core/lib/role.service";
import {DashboardController} from "../../core/controller/dashboard.controller";
import {NotificationConfirmDialogService} from "./notification-confirm-dialog/notification-confirm-dialog.service";
import {Router} from "@angular/router";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    providers: [ContractController, DashboardController]
})
export class DashboardComponent implements OnInit {

    masterCardList: CtCardConfiguration[] = [];
    cardList: CtCardConfiguration[] = [];
    counterList: CtCardConfiguration[] = [];

    constructor(
        private general: CTGeneralService,
        private contractController: ContractController,
        private roleService: RoleService,
        private dashboardController: DashboardController,
        private router: Router,
        private notificationConfirmDialogService: NotificationConfirmDialogService) {
    }

    ngOnInit() {

        this.setupMasterCards();

        this.setupShortcuts();

        this.setupCounters();

    }

    private async setupMasterCards() {

        const isTech = await this.roleService.is.tech();

        if (!isTech) {

            this.masterCardList
                .push(NEW_CONTRACT_CARD.setAction(() => this.general.navigateTo(['/', 'contract', 'create'])));

        }

        this.masterCardList
            .push(CONTRACT_LIST_CARD.setAction(() => this.general.navigateTo(['/', 'contract'])));

    }

    private async setupShortcuts() {

        this.cardList.push(
            NEW_CUSTOMER_CARD.setAction(() => this.general.navigateTo(['/', 'customer'])),
            CONTRACT_TYPE_LIST.setAction(() => this.general.navigateTo(['/', 'ct-model', 'contracttype'])),
            CONTRACT_OPTION_LIST.setAction(() => this.general.navigateTo(['/', 'ct-model', 'contractlookup'])))

        const isAdmin = await this.roleService.is.administrator();

        if (isAdmin) {

            this.cardList
                .push(
                    BILLING_NOTIFICATION_TRIGGER.setAction(() => {

                        this.notificationConfirmDialogService
                            .open()
                            .afterClosed()
                            .subscribe((result: Date | null) => this.dashboardController.invoiceNotification(result));

                    }),
                    GUARANTEE_NOTIFICATION_TRIGGER.setAction(() => this.dashboardController.warrantyNotification()));

        }

    }

    async setupCounters() {

        const {activeState, expiringState, expiredState} = {
            activeState: ContractState.Active,
            expiringState: ContractState.Expiring,
            expiredState: ContractState.Expired
        }

        const activeContractsCount = await this.getContractByState(activeState);
        this.setupContractCounter(ACTIVE_CONTRACTS_CARD, activeContractsCount, activeState);

        const expiringContractsCount = await this.getContractByState(expiringState);
        this.setupContractCounter(EXPIRING_CONTRACTS_CARD, expiringContractsCount, expiringState);

        const expiredContractsCount = await this.getContractByState(expiredState);
        this.setupContractCounter(EXPIRED_CONTRACTS_CARD, expiredContractsCount, expiredState);

    }

    private setupContractCounter(counter: CtCardConfiguration, result: CtModelConfiguration<any>, contractState: ContractState) {

        counter
            .setDescription((result?.TotalElement ?? 0) > 0 ? result.TotalElement : "0") // temporaneo, per mostrare 0 nel pannello
            .setAction(() => {

                const query = {
                    startUp: [
                        {
                            Value: [contractState],
                            OperatorType: CtBinaryOperator.InOperator,
                            Field: "State"
                        }
                    ],
                    forcedStartUpValues: true
                };

                this.router.navigate(['/', 'contract'], {queryParams: {q: JSON.stringify(query)}})

            })

        this.counterList.push(counter);

    }

    private getContractByState(state: ContractState) {

        const filter: CTModelDatatableFilter = CTModelDatatableFilter
            .create()
            .setField("State")
            .setValue(state)
            .setOperatorType(CtBinaryOperator.Equal);

        return this.contractController
            .get([filter]);

    }

}
