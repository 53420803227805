import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
    CtButtonConfiguration,
    CTGeneralService, CtModelConfiguration,
    CtModelService, FormHelperService,
    MAT_RAISED_PRIMARY,
    MAT_RAISED_WARN, SnackbarService
} from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse, CtWebapiService } from "@ctsolution/ct-webapi";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { Currency } from 'src/app/core/enum/currency';
import { ContractDTO } from "../../../core/class/contract-dto";
import { ContractTypeListElementDTO } from "../../../core/controller/contract-types.controller";
import { ContractController } from "../../../core/controller/contract.controller";
import { RoleService } from "../../../core/lib/role.service";
import { CONTRACT_ROUTEDATA } from "../contract.module";
import { ContractAlertFieldComponent } from "./contract-alert-field/contract-alert-field.component";
import { ContractDetailFieldComponent } from "./contract-detail-field/contract-detail-field.component";
import { ContractExtraFieldComponent } from "./contract-extra-field/contract-extra-field.component";
import { ContractExtraFieldConfiguration } from "./contract-extra-field/contract-extra-field.configuration";
import { ContractInfoFieldComponent } from "./contract-info-field/contract-info-field.component";
import { ContractRenewFieldComponent } from "./contract-renew-field/contract-renew-field.component";

@Component({
    selector: 'app-contract-create',
    templateUrl: './contract-create.component.html',
    styleUrls: ['./contract-create.component.scss'],
    providers: [ContractController]
})
export class ContractCreateComponent<T> {

    @ViewChild('ContractExtraField') contractExtraField: ContractExtraFieldComponent | null = null;
    @ViewChild('ContractMonitoringField') contractMonitoringField: ContractAlertFieldComponent | null = null;
    @ViewChild('ContractDetailField') contractDetailField: ContractDetailFieldComponent | null = null;
    @ViewChild('ContractInfoField') contractInfoField: ContractInfoFieldComponent | null = null;
    @ViewChild('ContractRenewField') contractRenewField: ContractRenewFieldComponent | null = null;

    cancelButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setClass('m-r-10')
        .setLabel("CT_PAGE.CT_FORM.cancel")
        .setAction(() => this.onCancel())
        .setMatherialOptions(MAT_RAISED_WARN)

    submitButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setLabel("CT_PAGE.CT_FORM.save")
        .setAction(() => this.onSubmit())
        .setMatherialOptions(MAT_RAISED_PRIMARY);

    configuration: CtModelConfiguration<ContractDTO<T>> = CtModelConfiguration
        .create<ContractDTO<T>>()
        .setRouteData(CONTRACT_ROUTEDATA());

    form: FormGroup;

    viewModel = {
        isTech: false
    }

    constructor(
        private formBuilder: FormBuilder,
        private _webapi: CtWebapiService,
        private general: CTGeneralService,
        private _model: CtModelService<ContractDTO<T>>,
        private route: ActivatedRoute,
        private formHelper: FormHelperService,
        private cdr: ChangeDetectorRef,
        private contractController: ContractController,
        private _snackbar: SnackbarService,
        private roleService: RoleService
    ) {

        this.form = this.formBuilder.group({});

        this.form
            .valueChanges
            .pipe(debounceTime(100), distinctUntilChanged())
            .subscribe(value => this.contractInfoField?.contractCodeCtrl?.setupContractCodeByFormValue(value))

    }

    ngAfterViewInit() {

        this.form
            .addControl('Info', this.contractInfoField?.form);

        this.form
            .addControl('Detail', this.contractDetailField?.form);

        this.setupPermissions()
            .then(() => this.setupContract());

        this.cdr.detectChanges();

    }

    private async setupPermissions() {

        this.viewModel.isTech = await this.roleService.is.tech();

        if (this.viewModel.isTech) {

            this.contractDetailField
                ?.form
                .disable();

            this.contractInfoField
                ?.form
                .disable();

            this.contractMonitoringField
                ?.form
                .disable();

            this.cancelButton
                .setLabel('Indietro')

        }

    }

    setupContract() {

        const paramId: string | null = this.route.snapshot.paramMap.get("id");

        if (paramId) {

            this.configuration
                ?.RouteData
                ?.setId(+paramId);

            this._model
                .getInfos(this.configuration)
                ?.subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<ContractDTO<T>>>) => {

                    const value = response.Result.DataSource as ContractDTO<T>;

                    if (!value) return;

                    value.User = (<any>value.User)?.CommercialCode;
                    value.Customer = (<any>value.Customer)?.Oid;
                    value.Supplier = (<any>value.Supplier)?.Oid;

                    value.StartDate = new Date(value.StartDate ?? 0);

                    this.setupContractValue(value)

                });

        }

    }

    async setupContractValue(value: ContractDTO<any>) {
        if ((value.ContractNumber ?? 0) <= 0) value.ContractNumber = null;

        value.InvoiceCurrency == 'CHF' ? value.Currency = Currency.CHF : value.Currency = Currency.EUR;

        this.contractDetailField
            ?.form
            .patchValue(value, {emitEvent: false});
        
        this.contractDetailField?.setupCustomerChangeSubscription();
        //Forzata la chiamata per non togliere l'emitEvent a false, non sapendo cosa comporta

        this.contractRenewField
            ?.form
            .patchValue(value);

        this.contractMonitoringField
            ?.form
            .patchValue(value);

        if (value.ContractType) {

            this.contractInfoField
                ?.contractTypeCtrl
                ?.setContractTypeValueByOid(value.ContractType.Oid);

        }

        this.contractExtraField
            ?.setFormValue(value.SpecificContract);
    }

    updateAmount(event: number | null = null) {

        if (!!event) {

            this.contractDetailField
                ?.setTotalValue(event);

        }

    }

    setupContractTypeExtraField(contractType: ContractTypeListElementDTO | null) {

        const extraFieldConfiguration: ContractExtraFieldConfiguration = ContractExtraFieldConfiguration
            .create()
            .setContractType(contractType);

        this.contractExtraField
            ?.setup(extraFieldConfiguration);

        this.contractDetailField
            ?.toggleTotalAmountValidation(contractType);

        if (contractType?.HasAlertAndMonitoring && !this.form.get('Monitoring')) {

            this.form
                .addControl('Monitoring', this.contractMonitoringField?.form);

        } else if (this.form.get('Monitoring')) {

            this.form.removeControl('Monitoring');

        }

        this.form.updateValueAndValidity();

        this.cdr.detectChanges();

    }

    onCancel() {

        this.general
            .back();

    }

    onSubmit(renew: boolean = false) {

        this.form.markAllAsTouched();

        if (this.form.valid) {

            const contractDetail = this.form.get('Detail')?.getRawValue(); // prende anche i valori disabilitati

            const contract: ContractDTO<T> = ContractDTO
                .create(contractDetail);

            contract
                .setUser(contractDetail.User)
                .setCustomer(contractDetail.Customer)
                .setSupplier(contractDetail.Supplier);

            const contractInfo = this.form.get('Info')?.getRawValue();

            if (contractInfo) contract.setContractType(+contractInfo.ContractType.Code);

            if (this.form.value.Monitoring) {

                const contractInfo = this.form.value.Monitoring;

                contract
                    .setMonitoring(contractInfo.Monitoring)
                    .setAlertManagement(contractInfo.AlertManagement)
                    .setAlertEmail(contractInfo.AlertEmail)

            }

            const specificContractFields = this.contractExtraField?.formContainer?.form?.getRawValue();

            if (this.contractExtraField) {

                contract
                    .setSpecificContract(specificContractFields);

            }

            if (renew) {

                this.contractController
                    .renewContract(contract)
                    .subscribe((response: CtWebapiGenericResponse<number>) => {

                        if (response.Result) {

                            this.general
                                .navigateTo(['/', 'contract', 'edit', (<any>response.Result)])

                        }

                    })

                return;

            }

            this._model
                .putInfos(this.configuration, contract)
                ?.subscribe(() => {

                    this.general
                        .navigateTo(['/', 'contract'])
                        .then(() => this._snackbar.open('CT_MESSAGES.success_operation'))

                });

        } else {

            this.formHelper.scrollToError();

        }

    }

}
